import React from "react";
import logo from "../assets/img/logo_takaoka_bots.png";
import { Link } from "react-router-dom";
import TakaokaContext from "./contexts/TakaokaContext";
import Sair from "./Sair";
import CookieService from "../services/CookieService";

const emailAdmin = process.env.REACT_APP_EMAIL_ADMIN;

class Menu extends React.Component {
  render() {
    let user = CookieService.get("user");

    return (
      <TakaokaContext.Consumer>
        {(context) => (
          <nav id="navbar" className="navbar-default navbar-static-side" role="navigation" >
            <div className="sidebar-collapse">
              <ul className="nav metismenu" id="side-menu" style={{ height:"100vh", overflowY:"scroll"}}>
                <li className="nav-header">
                  <div className="dropdown profile-element" style={{ textAlign: "center" }}>
                    <Link to="/">
                      {<img width="150px" src={logo} alt="Avatar" />}
                      <span className="block m-t-xs font-bold">{user?.name}</span>
                    </Link>
                  </div>
                  <div className="logo-element">IN+</div>
                </li>
                {user?.email == emailAdmin ? (
                  <li>
                    <Link to="/users">
                      <i className="fa fa-user"></i> <span className="nav-label">Users</span>
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                {user?.email == emailAdmin || user?.user_access?.some((e) => e.name == "Previsão Agendamentos") ? (
                  <li>
                    <Link to="/">
                      <i className="fa fa-user"></i> <span className="nav-label">Previsão Agendamentos</span>
                    </Link>
                    <ul class="nav nav-second-level collapse in" aria-expanded="true">
                      <li>
                          <Link to="/agrupamentos">
                            <i></i> <span className="nav-label">Grupos de hospitais</span>
                          </Link>
                        </li>
                      <li>
                        <Link to="/grupo-slots">
                          <i></i> <span className="nav-label">Slots</span>
                        </Link>
                      </li>
                    </ul>
                  </li>             
                ) : (
                  <></>
                )}
                {user?.email == emailAdmin || user?.user_access?.some((e) => e.name == "Importação Smart x SAP") ? (
                  <li>
                    <Link to="/">
                      <i className="fa fa-sliders"></i> <span className="nav-label">Importação SAP</span>
                    </Link>
                    <ul class="nav nav-second-level collapse in" aria-expanded="true">
                    <li>
                          <Link to="/agendamento-retroativo">
                            <i></i> <span className="nav-label">Agendamento Retroativo</span>
                          </Link>
                        </li>
                      <li>
                          <Link to="/reprocessar-sap">
                            <i></i> <span className="nav-label">Reprocessar</span>
                          </Link>
                        </li>
                      <li>
                        <Link to="/alterar-sap">
                          <i></i> <span className="nav-label">Alterar Status</span>
                        </Link>
                      </li>
                    </ul>
                  </li>             
                ) : (
                  <></>
                )}  
                {user?.email == emailAdmin || user?.user_access?.some((e) => e.name == "Agendamentos") ? (
                  <li>
                    <Link to="#">
                      <i className="fa fa-sliders"></i> <span className="nav-label">Agendamentos</span>
                    </Link>
                    <ul className="nav nav-second-level collapse in" aria-expanded="true">
                      <li>
                        <Link to="/de-para-convenios">
                          <i></i> <span className="nav-label">De-Para Convênios</span>
                        </Link>
                      </li>                    
                      <li>
                        <Link to="/importacao-lote-agendamentos">
                          <i></i> <span className="nav-label">Importação Lote</span>
                        </Link>
                      </li>                    
                    </ul>
                  </li>
                ) : (
                  <></>
                )}
                {user?.email == emailAdmin || user?.user_access?.some((e) => e.name == "Nota Fiscal") ? (
                  <li>
                    <Link to="#">
                      <i className="fa fa-sliders"></i> <span className="nav-label">Notas Fiscais</span>
                    </Link>
                    <ul className="nav nav-second-level collapse in" aria-expanded="true">
                      <li>
                        <Link to="/notafiscal/individual">
                          <i></i> <span className="nav-label">Nota Individual</span>
                        </Link>
                      </li>    
                      <li>
                        <Link to="/notafiscal/npedidos">
                          <i></i> <span className="nav-label">Nota N Pedidos</span>
                        </Link>
                      </li>                                  
                      <li>
                        <Link to="/notafiscal/convenios">
                          <i></i> <span className="nav-label">Convênio - Lotes</span>
                        </Link>
                      </li>      
                    </ul>
                  </li>
                ) : (
                  <></>
                )}             
                {user?.email == emailAdmin || user?.user_access?.some((e) => e.name == "Geração XML") ? (
                  <li>
                    <Link to="#">
                      <i className="fa fa-sliders"></i> <span className="nav-label">Fat. Geração XML</span>
                    </Link>
                    <ul className="nav nav-second-level collapse in" aria-expanded="true">
                      <li>
                        <Link to="/agendamentos">
                          <i></i> <span className="nav-label">Via - Agendamentos</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/revisao-agendamentos">
                          <i></i> <span className="nav-label">Revisão Agendamentos</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/revisao-lotes">
                          <i></i> <span className="nav-label">Revisão Lotes</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/processamento-manual">
                          <i></i> <span className="nav-label">Calculo Manual Agendamento</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/crm-cpf">
                          <i></i> <span className="nav-label">CRM-CPF</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/cnes-cnpj">
                          <i></i> <span className="nav-label">CNES-CNPJ</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/matriz-convenios">
                          <i></i> <span className="nav-label">Convênio Proc.</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/depara">
                          <i></i> <span className="nav-label">De-Para</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/feriados">
                          <i></i> <span className="nav-label">Feriados</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/matriz">
                          <i></i> <span className="nav-label">Matriz</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/operadoras">
                          <i></i> <span className="nav-label">Operadoras</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/portes">
                          <i></i> <span className="nav-label">Portes</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/tuss">
                          <i></i> <span className="nav-label">TUSS</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <></>
                )}
                {user?.email == emailAdmin || user?.user_access?.some((e) => e.name == "Demonstrativo Pagamento") ? (
                  <li>
                    <Link to="#">
                      <i className="fa fa-sliders"></i> <span className="nav-label">Demonst. pagamento</span>
                    </Link>
                    <ul className="nav nav-second-level collapse in" aria-expanded="true">
                      <li>
                        <Link to="/desconto-medico">
                          <i></i> <span className="nav-label">Desconto médico</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/processar-demonstrativo">
                          <i></i> <span className="nav-label">Processar</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <></>
                )}
                {user?.email == emailAdmin || user?.user_access?.some((e) => e.name == "Automação Carga H9J") ? (
                  <li>
                    <Link to="#">
                      <i className="fa fa-sliders"></i> <span className="nav-label">Carga H9J</span>
                    </Link>
                    <ul className="nav nav-second-level collapse in" aria-expanded="true">
                      <li>
                        <Link to="/de-para-procedimentos">
                          <i></i> <span className="nav-label">De-Para Procedimentos</span>
                        </Link>
                      </li>                      
                    </ul>
                  </li>
                ) : (
                  <></>
                )}
                {user?.email == emailAdmin || user?.user_access?.some((e) => e.name == "Pesquisa de Avaliação - Mailing") ? (
                  <li>
                    <Link to="#">
                      <i className="fa fa-sliders"></i> <span className="nav-label">Pesquisa de Satisfação</span>
                    </Link>
                    <ul className="nav nav-second-level collapse in" aria-expanded="true">
                      <li>
                        <Link to="/pesquisa-avaliaca">
                          <i></i> <span className="nav-label">Mailing</span>
                        </Link>
                      </li>                      
                    </ul>
                  </li>
                ) : (
                  <></>
                )}           
                {user?.email == emailAdmin || user?.user_access?.some((e) => e.name == "Relatório Personalizado") ? (
                  <li>
                    <Link to="#">
                      <i className="fa fa-sliders"></i> <span className="nav-label">Relatório Personalizado</span>
                    </Link>
                    <ul className="nav nav-second-level collapse in" aria-expanded="true">
                      <li>
                        <Link to="/relatorio-personalizado/anestesistas-inclusao">
                          <i></i> <span className="nav-label">Anestesistas do Personalizado</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/relatorio-personalizado/anestesistas-exclusao">
                          <i></i> <span className="nav-label">Anestesistas DOR - Excluídos</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/relatorio-personalizado/procedimentos-anestesista-exclusao">
                          <i></i> <span className="nav-label">Procedimentos Excluídos</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/relatorio-personalizado/ticket-medio">
                          <i></i> <span className="nav-label">Ticket médio</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <></>
                )}     
                {user?.email == emailAdmin || user?.user_access?.some((e) => e.name == "Takaoka") ? (
                  <li>
                    <Link to="#">
                      <i className="fa fa-sliders"></i> <span className="nav-label">Takaoka</span>
                    </Link>
                    <ul className="nav nav-second-level collapse in" aria-expanded="true">
                      <li>
                        <Link to="/medicos">
                          <i></i> <span className="nav-label">Médicos</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <></>
                )}
                <li>
                  <Sair />
                </li>
              </ul>
            </div>
          </nav>
        )}
      </TakaokaContext.Consumer>
    );
  }
}

export default Menu;
